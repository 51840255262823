import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { ApplyAndWhatsNextSectionsDevCamp, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className='bg-primary-1 text-primary-3'>
    <Analytics />
    <SEO
      title='DevCamp Summer Internship'
      pageUrl='/careers/internship/'
      type='article'
      cardImage='/img/tumbacardjobs1.png'
      description='Looking for an internship in a tech company to speedrun your IT career? Join DevCamp and learn the latest iOS/Android development trends with great mentors.'
    />
    {/*<LdJsonJobPosting*/}
    {/*  title='DevCamp Summer Internship'*/}
    {/*  description='Looking for an internship in a tech company to speedrun your IT career? Join DevCamp and learn the latest mobile development trends with great mentors.'*/}
    {/*/>*/}
    <MainWithMenu className='relative grid'>
      <Header className='z-20 pt-6 pb-24'>
        <HeaderLink className='text-primary-2' />
        <Link to='/careers/'>
          <div className='py-12 text-left'>
            <h1 className='py-4 text-4xl lg:text-5xl text-primary-3 text-shadow'>Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className='z-10 bg-primary-2' decoType={2} before after gridRow={2} />
      <Section className='z-20 lg:mr-48' gridRow={2}>
        <PositionIcon className='w-12 sm:w-20 text-primary-1 fill-current' />
        <h2 className='text-3xl lg:text-4xl text-primary-1'>DevCamp Summer Internship</h2>
        <div className='mt-4 mb-16 text-lg lg:text-xl tracking-normal'>
          <p className='sm:mt-6 font-normal'>
            DevCamp builds the connection between the academic and practical skills needed for a successful IT career.
            We will give you that twist and magic that makes you the outlier - the one to conquer the next peak. And our
            mentors will support you
            throughout the whole process.
          </p>
          <p className='sm:mt-6 font-normal'>
            The program is 3 months long and it is focused on developing an understanding and practical skills in
            iOS/Android app development using Swift, Java and Kotlin languages. Starting off with language foundations
            and specifics, IDE, then OOP
            principles, creating UI, networking, data persistence, design patterns and more.
          </p>
        </div>
        <div className='grid gap-12 grid-cols-1 lg:grid-cols-2'>
          <div>
            <h3 className='text-xl lg:text-2xl text-primary-1'>What can you expect?</h3>
            <div className='mt-4 text-lg lg:text-xl font-normal tracking-normal'>
              <ul className='pl-10 list-disc'>
                <li className='mt-4'>
                  <strong className='text-primary-1 font-normal'>Seize the opportunity</strong> to learn the latest
                  trends in Mobile development.
                </li>
                <li className='mt-4'>
                  <strong className='text-primary-1 font-normal'>Get professional mentorship</strong> and join a team of
                  passionate developers.
                </li>
                <li className='mt-4'>
                  <strong className='text-primary-1 font-normal'>Join a dynamic program</strong> combining structured
                  information with practical home assessments.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className='text-xl lg:text-2xl text-primary-1'>Core Requirements</h3>
            <div className='mt-4 text-lg lg:text-xl font-normal tracking-normal'>
              <ul className='pl-10 list-disc'>
                <li className='mt-4'>Tech basics gained at University or Tech school</li>
                <li className='mt-4'>Interest in Mobile development</li>
                <li className='mt-4'>Experience in Swift/Java/Kotlin is a plus but not required</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className='text-xl lg:text-2xl text-primary-1'>Program details</h3>
            <div className='mt-4 text-lg lg:text-xl font-normal tracking-normal'>
              <ul className='pl-10 list-disc'>
                <li className='mt-4'>Duration: July - September 2023</li>
                <li className='mt-4'>2-3 days per week live sessions in Sofia</li>
                <li className='mt-4'>3-4 hours per day</li>
                <li className='mt-4'>Theory, practice & HW</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSectionsDevCamp position='DevCamp' active={false} />
      <Nav gridRow={7} className='z-20 text-primary-2' />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
